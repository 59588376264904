:root { /* #235789 #083D77 */ /* #235789 */ /* #FFED00 */
}

.primary-color { color: rgba(8, 61, 119, 1.0); }

.bg-primary-color { background-color: rgba(8, 61, 119, 1.0); }

.b--primary-color { border-color: rgba(8, 61, 119, 1.0); }

.accentuated-color { color: rgba(255, 237, 0, 1); }

.bg-accentuated-color { border-color: rgba(255, 237, 0, 1); }

.b--accentuated-color { border-color: rgba(255, 237, 0, 1); }

.bg-gradient {
  border-bottom: #c4c4c4 1px solid;
}

em {
    background-color: rgba(255, 237, 0, 1);
}

button, input {
    border: none !important;
}

button:focus, input:focus {
    outline: none !important;
}

input[type="search"]::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}

input[type="search"]::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

h1,h2,h3 {
    letter-spacing: .1em;
    line-height: 1.5;
}

.button:hover .tooltip{
    display: inline;
      top: 10px;
      left: 5px;
      width: 22rem;
      position: relative;
}

/* START TOOLTIP STYLES */

[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */

[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
        content: attr(tooltip); /* magic! */

        /* most of the rest of this is opinion */
        font-family: Helvetica, sans-serif;
        text-align: center;

        /*
        Let the content set the size of the tooltips
        but this will also keep them from being obnoxious
       */
        white-space: normal;
        text-overflow: ellipsis;
        padding: 1ch 1.5ch;
        border-radius: .3ch;
        box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
        background: #333;
        color: #fff;
        height: 9rem;
        width: 20rem;
        z-index: 1000;
        vertical-align: middle !important;
}

.one [tooltip]::after {
    height: 2rem !important;
}

/* Make the tooltips respond to hover */

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */

[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */

[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -.5em);
          transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */

[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, .5em);
          transform: translate(-50%, .5em);
}

/* FLOW: LEFT */

[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0 - 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}

[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0 - 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}

/* KEYFRAMES */

@-webkit-keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@-webkit-keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

/* FX All The Things */

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  -webkit-animation: tooltips-vert 300ms ease-out forwards;
          animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  -webkit-animation: tooltips-horz 300ms ease-out forwards;
          animation: tooltips-horz 300ms ease-out forwards;
}

/* UNRELATED to tooltips */

body {
  margin: 0;
  min-height: 100vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  font-family: sans-serif;
  background: #ededed;
}

main {
  -webkit-box-flex: 1;
          flex: 1 1 100vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

main div {
  text-align: center;
  color: #353539;
}

main span {
  padding: .5em 1em;
  margin: 0 .5em;
  display: inline-block;

}

.nav-link-active {
    border-color: rgba(8, 61, 119, 1.0);
    border-width: 0.2rem;
    border-bottom-style: dotted;
}

.hover-nav-link:focus {
    border-color: rgba(8, 61, 119, 1.0);
    border-width: 0.2rem;
    border-bottom-style: dotted;
}

.hover-nav-link:hover {
    border-color: rgba(8, 61, 119, 1.0);
    border-width: 0.2rem;
    border-bottom-style: dotted;
}

.f6 {font-size: .7rem;}

.logo img  {  max-height: 2.5rem;}

h2 {margin: 0}
